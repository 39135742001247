import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Product, ProductImg } from "services/api/types/Product";
import FormatPrice from "lib/FormatPrice";
import NoImage from "shared/NoImage";

const ProductColor = ({imageEl, changeImage}: {imageEl: ProductImg, changeImage: (src: string) => void}) => {
	return (
		<span 
			className="catalog-list-item__color-img"
			onMouseEnter={() => changeImage(imageEl.thumbnail)}>
			<img src={imageEl.thumbnail} alt="цвет" title="дополнительный цвет"/>
		</span>
	);
};

const CatalogListItem = ({product, search = false, urlFrom}: {product: Product, search?: boolean, urlFrom?: string}) => {
	const location = useLocation();
	const startImg = product.images.find(el => el.is_exterior === true) || product.images[0];
	const [imgUrl, setImgUrl] = useState(startImg ? startImg.thumbnail : '');
	const categoryUrl = product.categories[0].slug;
	let startUrl: string;
	if (urlFrom) {
		startUrl = urlFrom;
	} else {
		startUrl = search ? `/search`: location.pathname;
	}
	
	
	const changeImage = (src) => {
		setImgUrl(src);
	};
	return (
		<li itemScope itemProp="itemListElement" itemType="http://schema.org/Product">
			<Link to={`${startUrl}/catalog/${product.slug}`} className="catalog-list-item" itemProp="url" >
				<div className="catalog-list-item__photo">
					{startImg 
						? 	<img src={imgUrl} alt={`${product.short_name} - фото`} title={product.short_name} itemProp="image" />
						: 	<NoImage/>
					}
				</div>
				<span className="catalog-list-item__name" itemProp="name">{product.short_name}</span>
				{product.price 
					?	<span className="catalog-list-item__price" itemScope itemProp="offers" itemType="http://schema.org/Offer">
							Цена: От {product.discount_price ? <><span className="catalog-list-item__price_old">{FormatPrice(product.price)}</span> {FormatPrice(product.discount_price)}</> : FormatPrice(product.price)} &#8381;
							<meta itemProp="priceCurrency" content="RUB" />
						</span>
					:	''
				}
				{(product.images.length > 1) 
					? <div className="catalog-list-item__color">
						{product.images.slice(0, 5).map(el => <ProductColor key={`${product.id}_${el.image}`} imageEl={el} changeImage={changeImage}/>)}
						{(product.images.length > 6) && <span>+{product.images.length - 6}</span>}
					</div>
					: <div className="catalog-list-item__color catalog-list-item__color--empty"></div>
				}
				<span className="catalog-list-item__link">Подробнее</span>
				<div className="catalog-list-item__billets">
					{product.is_new 
						? <span className="catalog-list-item__billet catalog-list-item__new">Новинка</span>
						: null
					}
					{product.is_termo  
						? <span className="catalog-list-item__billet catalog-list-item__termo">Термо</span>
						: null
					}
					{product.is_lock  
						? <span className="catalog-list-item__billet catalog-list-item__lock">Умный замок</span>
						: null
					}
				</div>
				<meta itemProp="description" content={`${product.short_name} в Орле по низкой цене. Широкий ассортимент, высокое качество товаров для ремонта и обустройства дома.`}/>
			</Link>
		</li>
	)
};
export default CatalogListItem;