import { NavLink, Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import logoImg from 'assets/images/city.svg';
import vk from 'assets/images/vk.png';
import whatsappImg from 'assets/images/whatsapp.svg';
import telegramImg from 'assets/images/telegram.svg';

const Footer = ({openModal}: {openModal?: (state: string) => void}) => {
  const location = useLocation();

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <footer itemScope itemType="http://schema.org/WPFooter">
      <div className="footer container">
      <Link to="/" className="footer__logo logo">
					<span className="logo_img"><img src={logoImg} alt="Логотип компании"/></span>
					<span className="logo_text">КОМФОРТ+</span>
					<span className="logo_sub">магазин дверей</span>
				</Link>
        <p className="footer__adr adr">г. Орел ул. Пушкина д. 4<br /> ТЦ "Радуга" 2-ой этаж</p>
        <p className="footer__phone tel">
          <a href="tel:+74862632340">+7 (4862) 63-23-40</a>
          <span className='phones-row'>
						<a href="tel:+79300632340">+7 (930) 063-23-40</a>
						<a className="messenger" href="https://wa.me/79300632340" target="_blank"><img src={whatsappImg} alt="Whatsapp" width={20}/></a>
						<a className="messenger" href="https://t.me/+79300632340" target="_blank"><img src={telegramImg} alt="Telegram" width={20}/></a>
					</span>
        </p>
        <a className="footer__link" href="https://vk.com/comfortplusorel" target="_blank" rel="nofollow noopener noreferrer">
          <img src={vk} alt="vk" title="vk" />
        </a>
        <div className="footer__docs">
          <button className="footer__btn" onClick={() => openModal('Вызвать замерщика')}>Вызвать замерщика</button>
          <a href={process.env.PUBLIC_URL + '/politic.pdf'} target="_blank" className="footer__politic">Политика конфидециальности</a>
        </div>
        <div className="footer__copy">
          <p className="footer__copyright">©2023-{new Date().getFullYear()} ООО&nbsp;КОМФОРТ&nbsp;+</p>
          <p className="footer__creator">Создание и поддержка сайта <a href="https://sait-region.ru/" target='_blank'>ООО "Регион центр"</a></p>
        </div>
        <ul className="footer__menu">
          <li>
            <NavLink to="/">Главная</NavLink>
          </li>
          <li><NavLink to="/vhodnye-dveri" itemProp="url">Входные двери</NavLink></li>
          <li><NavLink to="/mezhkomnatnye-dveri" itemProp="url">Межкомнатные двери</NavLink></li>
          <li><NavLink to="/razdvizhnye-peregorodki" itemProp="url">Раздвижные перегородки</NavLink></li>
          <li><NavLink to="/furnitura" itemProp="url">Фурнитура</NavLink></li>
          <li><NavLink to="/rasprodazha" itemProp="url">Распродажа</NavLink></li>
          <li><NavLink to="/kontakty" itemProp="url">Контакты</NavLink></li>
        </ul>
      </div>
      <meta itemProp="copyrightYear" content={`${new Date().getFullYear()}`} />
      <meta itemProp="copyrightHolder" content="ООО КОМФОРТ +" />
      {/* <div className="up-link-wrap">
        <span className="up-link" onClick={scrollTop}>
          <span className="glyphicon glyphicon-chevron-up"></span>
        </span>
      </div> */}
    </footer>
  )
}
export default Footer;