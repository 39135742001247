import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState, useRef, useContext } from 'react';
import Menu from '../Menu/Menu';
import { ViewportContext } from '../../app/viewportContext'; 
import logoImg from 'assets/images/city.svg';
import whatsappImg from 'assets/images/whatsapp.svg';
import telegramImg from 'assets/images/telegram.svg';
import Search from 'features/Search/Search';

const Header = ({setPaddingtop, openModal}: {setPaddingtop: (height: number) => void, openModal?: (state: string) => void}) => {
	const width = useContext(ViewportContext);
	const location = useLocation();
	const [showMenu, setShowMenu] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [isFixed, setIsFixed] = useState(false);
	const [freeheight, setFreeheight] = useState(0);
	const headerRef = useRef(null);
	let startHeaderHeight = 0;

	useEffect(() => {
		setShowMenu(false);
		// setModalShow(false);
	}, [location]);

	const handleScroll = () => { 
		if (window.pageYOffset > headerRef.current.clientHeight) {
			setIsFixed(true);
			setPaddingtop(startHeaderHeight);
		} else {
			setIsFixed(false);
			setPaddingtop(0);
		}
	};

	useEffect(() => {
		startHeaderHeight = headerRef.current.clientHeight;
		window.addEventListener('scroll', handleScroll)
		return () => {
			startHeaderHeight = 0;
			window.removeEventListener('scroll', handleScroll)
		}
	}, []);

	const toggleMenu = () => {
		setShowMenu(state => !state);
		if (width < 481) {
			setFreeheight(window.innerHeight - headerRef.current.clientHeight);
		} 
	};
	return (
		<header ref={headerRef} className={isFixed ? 'fixed' : ''}>
			<div className="header container">
				<Link to="/" className="header__logo logo">
					<span className="logo_img"><img src={logoImg} alt="Логотип компании"/></span>
					<span className="logo_text">КОМФОРТ+</span>
					<span className="logo_sub">магазин дверей</span>
				</Link>
				<p className="header__adr adr">г. Орел ул. Пушкина д. 4<br/> ТЦ "Радуга" 2-ой этаж</p>
				<p className="header__phone tel">
					<a href="tel:+74862632340">+7 (4862) 63-23-40</a>
					<span className='phones-row'>
						<a href="tel:+79300632340">+7 (930) 063-23-40</a>
						<a className="messenger" href="https://wa.me/79300632340" target="_blank"><img src={whatsappImg} alt="Whatsapp" width={20}/></a>
						<a className="messenger" href="https://t.me/+79300632340" target="_blank"><img src={telegramImg} alt="Telegram" width={20}/></a>
					</span>
				</p>
				<button className="header__btn" onClick={() => openModal('Вызвать замерщика')}>Вызвать замерщика</button>
				<button className="header__btn header__btn--search" onClick={() => setShowSearch(state => !state)}></button>
				<div className="toggle-wrapper" onClick={toggleMenu}>
					<div className={`menu-icon menu-icon-3 ${showMenu ? 'opened' : ''}`}>
						<div className="bar bar-1"></div>
						<div className="bar bar-2"></div>
						<div className="bar bar-3"></div>
					</div>
				</div>
			</div>
			<nav className={`${showMenu ? 'opened' : ''}`} style={width <= 480 ? {height: freeheight} : {height: 'auto'}}> 
				<Menu />
			</nav>
			{showSearch && <Search setShow={setShowSearch}/>}
		</header>
	)
}
export default Header;