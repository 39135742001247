import { useEffect, useRef, useState } from 'react';
import HeadTags from '../../shared/HeadTags';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useNavigate } from 'react-router-dom';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import vk from 'assets/images/vk_icon_red.png';
import map_img from 'assets/images/map_img.jpg';
import location_pin from 'assets/images/location-pin.png';
import whatsappImg from 'assets/images/whatsapp.svg';
import telegramImg from 'assets/images/telegram.svg';
import ym from 'react-yandex-metrika';

const sliderImages = [
	{ 
		small: require('assets/images/contacts/1.jpg'),
		big: require('assets/images/contacts/big/1.jpg')
	},
	{ 
		small: require('assets/images/contacts/2.jpg'),
		big: require('assets/images/contacts/big/3.jpg')
	},
	{ 
		small: require('assets/images/contacts/3.jpg'),
		big: require('assets/images/contacts/big/3.jpg')
	},
	{ 
		small: require('assets/images/contacts/4.jpg'),
		big: require('assets/images/contacts/big/4.jpg')
	},
	{ 
		small: require('assets/images/contacts/6.jpg'),
		big: require('assets/images/contacts/big/6.jpg')
	},
	{ 
		small: require('assets/images/contacts/7.jpg'),
		big: require('assets/images/contacts/big/7.jpg')
	},
	{ 
		small: require('assets/images/contacts/8.jpg'),
		big: require('assets/images/contacts/big/8.jpg')
	},
	{ 
		small: require('assets/images/contacts/11.jpg'),
		big: require('assets/images/contacts/big/11.jpg')
	},
	{ 
		small: require('assets/images/contacts/12.jpg'),
		big: require('assets/images/contacts/big/12.jpg')
	},
	{ 
		small: require('assets/images/contacts/13.jpg'),
		big: require('assets/images/contacts/big/13.jpg')
	},
	{ 
		small: require('assets/images/contacts/15.jpg'),
		big: require('assets/images/contacts/big/15.jpg')
	},
	{ 
		small: require('assets/images/contacts/16.jpg'),
		big: require('assets/images/contacts/big/16.jpg')
	},
	{ 
		small: require('assets/images/contacts/17.jpg'),
		big: require('assets/images/contacts/big/17.jpg')
	},
	{ 
		small: require('assets/images/contacts/18.jpg'),
		big: require('assets/images/contacts/big/18.jpg')
	},
	{ 
		small: require('assets/images/contacts/19.jpg'),
		big: require('assets/images/contacts/big/19.jpg')
	},
	{ 
		small: require('assets/images/contacts/21.jpg'),
		big: require('assets/images/contacts/big/21.jpg')
	}
];
const ContactsPage = ({openModal}: {openModal?: (state: string) => void}) => {
	const navigate = useNavigate();
	const [galleryIndex, setGalleryIndex] = useState(-1);
	let bigImages = sliderImages.map(el => {return ({src: el.big})});
	const handleGalleryClick = (index: number) => setGalleryIndex(index);	

	useEffect(() => {
		ym('hit', `/kontakty`);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [navigate]);	

	return (
		<div className="contacts__wrapper">
			<HeadTags
				title='Контакты - магазин в Орле'
				metaDescription='Контактная информация'
			/>
			<section className="contacts container">
                <h1>Контактная информация</h1>
                <div className="contacts__map">
					<YMaps>
						<Map
							state={{
								center: [52.966817, 36.077543],
								zoom: 16,
							}}
							width={'100%'}
							height={'100%'}
							options={{autoFitToViewport: 'always'}}
						>
							<Placemark
								geometry={[52.966817, 36.077543]}
								options={{
									iconLayout: 'default#image',
									iconImageHref: location_pin,
									iconImageSize: [68, 71],
									iconImageOffset: [-34, -71]
								}}
								modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
								properties={{
									iconCaption: 'Комфорт+',
									baloonContent: 'baloonContent',
									hintContent:
									  `<div class="map-img"><img src="${map_img}"></div>`,							  
								}}
							/>
						</Map>
					</YMaps>
                </div>
                <div className="contacts__block">
                    <p className="tel">
                        <a href="tel:+74862632340">+7 (4862) 63-23-40</a>
                        <span className='phones-row'>
						<a href="tel:+79300632340">+7 (930) 063-23-40</a>
							<a className="messenger" href="https://wa.me/79300632340" target="_blank"><img src={whatsappImg} alt="Whatsapp" width={20}/></a>
							<a className="messenger" href="https://t.me/+79300632340" target="_blank"><img src={telegramImg} alt="Telegram" width={20}/></a>
						</span>
                    </p>
                    <a className="mail" href="mailto:comfortplus57@gmail.com">comfortplus57@gmail.com</a>
                    <p className="adr" >г. Орел ул. Пушкина д. 4, ТЦ "Радуга" 2-ой этаж</p>
                    <p className="time">пн-пт: с 10.00 до 19.00<br/>
                    сб-вс: с 10.00 до 18.00</p>
					<div className="contacts__block_btns">
						<a className="vk" href="https://vk.com/comfortplusorel" target="_blank" rel="nofollow noopener noreferrer">
							<img src={vk} alt="vk" title="vk"/>
						</a>
						<button className="contacts__block_btn" onClick={() => openModal('Задать вопрос')}>Задать вопрос</button>	  
					</div>
    	        </div>
                <div className="contacts__slider">
					<Swiper
					spaceBetween={20}
					modules={[Navigation]}
					loop={true}
					autoplay={{
						disableOnInteraction: false,
					}}
					navigation={{
						nextEl: '#next',
						prevEl: '#prev',
					}}
					breakpoints={{
						480: {
							slidesPerView: 3,
						},
						640: {
							slidesPerView: 4,
						},
						900: {
							slidesPerView: 5,
							spaceBetween: 50
						},
					}}
					>
					{sliderImages.map((el, i) => {
						return (
						<SwiperSlide key={i}>
							<div className="main-partners-list__item" onClick={() => handleGalleryClick(i)}>
								<img alt="Двери - фото" src={el.small} title="Двери" />
							</div>
						</SwiperSlide>
						)
					})}
					</Swiper>
					<div className="swiper-button-prev" id="prev"></div>
					<div className="swiper-button-next" id="next"></div>
                </div>
            </section>
			<Lightbox
				slides={bigImages}
				open={galleryIndex >= 0}
				index={galleryIndex}
				close={() => setGalleryIndex(-1)}
			/>
		</div>
	)
}
export default ContactsPage;